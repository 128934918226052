<header [class.opaque]="opaque || mmMainActive || mmAccountActive">
    <div class="h-content-desktop">
        <div class="h-logolangs">
            <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.svg" alt="logo"></a></div>
            <div class="h-langs">
                <ul>
                    <li *ngFor="let l of langs"><a [routerLink]="getLangLink(l)" [class.active]="lang.id === l.id">{{l.slug}}</a></li>
                </ul>                
            </div>
        </div>
        <nav class="h-menu">
            <ul>
                <li><a (click)="onMenuClick('about')">{{words['header']?.['about']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('partners')">{{words['header']?.['partners']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('services')">{{words['header']?.['services']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('footer')">{{words['header']?.['contact']?.[lang.slug]}}</a></li>
            </ul>
        </nav>
        <div class="h-phone">
            <a href="tel:{{words['contacts']?.['phone-canonical']?.[lang.slug]}}">{{words['contacts']?.['phone']?.[lang.slug]}}</a>
        </div>
        <div class="h-account">
            <ng-container *ngIf="authenticated">
                <div class="ha-notifications">
                    <div class="smallbtn notifications" (click)="pNotificationsActive = true"></div>
                    <panel-notifications [(active)]="pNotificationsActive"></panel-notifications>
                </div>
                <div class="ha-links">
                    <a routerLink="/{{lang.slug}}/account" [class.active]="url[2] === 'account' && !url[3]">{{words['header']?.['account']?.[lang.slug]}}</a>
                    <div class="hal-border"></div>
                    <a class="danger" (click)="pLogoutActive = true">{{words['header']?.['logout']?.[lang.slug]}}</a>
                    <panel-logout [(active)]="pLogoutActive"></panel-logout>
                </div>
            </ng-container>
            <ng-container *ngIf="!authenticated">
                <div class="ha-links">
                    <a (click)="pLoginActive = true">{{words['header']?.['login']?.[lang.slug]}}</a>
                    <div class="hal-border"></div>
                    <a (click)="pRegisterActive = true">{{words['header']?.['register']?.[lang.slug]}}</a>
                </div>
            </ng-container>            
        </div>
    </div>   
    <div class="h-content-mobile">
        <div class="smallbtn" [class.menu]="!mmMainActive" [class.close]="mmMainActive" (click)="toggleMMMain()"></div>
        <div class="h-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.svg" alt="logo"></a></div>
        <div class="smallbtn" [class.account]="!mmAccountActive" [class.close]="mmAccountActive" (click)="toggleMMAccount()"></div>
    </div> 
    <mm-main [(active)]="mmMainActive" (menuClick)="onMenuClick($event)"></mm-main>
    <mm-account [(active)]="mmAccountActive"></mm-account>
</header>
