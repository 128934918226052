import { Directive } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { ICard } from "src/app/model/entities/card.interface";
import { CardSubtype } from "src/app/model/entities/cardtype.interface";
import { ILang } from "src/app/model/entities/lang.interface";
import { IWords } from "src/app/model/entities/words.interface";

@Directive()
export abstract class TerminalComponent {
    public abstract card: ICard;
    public pSendBankActive: boolean = false;
    public pSendCryptoActive: boolean = false;
    public pReceiveBankActive: boolean = false;
    public pReceiveCryptoActive: boolean = false;
    public reloadInitiator: number = 0;

    constructor(protected appService: AppService) {}

    get words(): IWords {return this.appService.words;}      
    get lang(): ILang {return this.appService.lang;}  

    public onTransactionSent(): void {
        this.reloadInitiator++; // just change param to init reload
    }

    public onSend(): void {
        const p = this.card.type.subtype === CardSubtype.Bank ? "pSendBankActive" : "pSendCryptoActive";
        this[p] = true;
    }

    public onReceive(): void {
        const p = this.card.type.subtype === CardSubtype.Bank ? "pReceiveBankActive" : "pReceiveCryptoActive";
        this[p] = true;
    }
}