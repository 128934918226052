import { Component, EventEmitter, Output } from "@angular/core";
import { ILang } from "src/app/model/entities/lang.interface";
import { MMComponent } from "../mm.component";

@Component({
    selector: "mm-main",
    templateUrl: "mm-main.component.html",
    styleUrls: [
        "../mm.component.scss",
        "mm-main.component.scss",
    ]
})
export class MMMainComponent extends MMComponent {
    @Output() private menuClick: EventEmitter<string> = new EventEmitter();

    get langs(): ILang[] {return this.appService.langs;}

    public onMenuClick(blockName: string): void {
        this.menuClick.emit(blockName);
        this.close();
    }

    public getLangLink(lang: ILang): string {
        return this.appService.getLangLink(lang);
    }
}