<div class="popup-wrap" [class.active]="active" (click)="onClick($event)">
    <div class="popup" id="popup-{{unique}}">
        <div class="popup-head"><div class="smallbtn close" (click)="onClose()"></div></div>
        <div class="popup-title">{{words['receive']?.['title']?.[lang.slug]}}</div>
        <div class="popup-content compact">
            <div class="creds" *ngIf="user">
                <div class="c-item">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['fullname']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(userFullname)"></div>
                        </div>
                        <div class="ci-value">{{userFullname}}</div>
                    </div>                    
                </div>
                <div class="c-item">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['addr']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(user.addr)"></div>
                        </div>
                        <div class="ci-value">{{user.addr}}</div>
                    </div>                    
                </div>
                <div class="c-item rowspan3">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['no']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(card.no)"></div>
                        </div>
                        <div class="ci-value">{{card.no}}</div>
                    </div>   
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['cvv']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(card.cvv)"></div>
                        </div>
                        <div class="ci-value">{{card.cvv}}</div>
                    </div>
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['exp']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(cardExp)"></div>
                        </div>
                        <div class="ci-value">{{cardExp}}</div>
                    </div>               
                </div>
                <div class="c-item">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['iban']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(card.iban)"></div>
                        </div>
                        <div class="ci-value">{{card.iban}}</div>
                    </div>                    
                </div>
                <div class="c-item">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['account-no']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(card.account_no)"></div>
                        </div>
                        <div class="ci-value">{{card.account_no}}</div>
                    </div>                    
                </div>
                <div class="c-item">
                    <div class="ci-content">
                        <div class="ci-head">
                            <div class="ci-title">{{words['receive']?.['swift']?.[lang.slug]}}</div>
                            <div class="ci-btn" (click)="copy(card.swift)"></div>
                        </div>
                        <div class="ci-value">{{card.swift}}</div>
                    </div>                    
                </div>
            </div>      
        </div>
    </div>
</div>
