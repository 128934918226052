<!-- head -->
<div class="home-head">
    <div class="hh-wrap">
        <div class="hh-subwrap">
            <div class="hh-content">
                <list-paysystems></list-paysystems>
                <h1>{{words['home']?.['head-title']?.[lang.slug]}}</h1>
                <a class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['head-btn']?.[lang.slug]}}</span></a>
            </div>
            <div class="hh-arrowswrap">
                <div class="hh-arrows" (click)="scrollTo('about')"></div>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <!-- about -->
    <h2 class="ta-center" name="about">{{words['home']?.['about-title']?.[lang.slug]}}</h2>
    <section>
        <div class="home-about">
            <div class="ha-item">
                <div class="ha-imgwrap"><img src="/assets/images/home-about1.png" loading="lazy" alt="about us"></div>
                <div class="ha-title">{{words['home']?.['about1-title']?.[lang.slug]}}</div>
                <div class="ha-txt" [innerHTML]="words['home']?.['about1-txt']?.[lang.slug]"></div>
            </div>
            <div class="ha-item">
                <div class="ha-imgwrap"><img src="/assets/images/home-about2.png" loading="lazy" alt="about us"></div>
                <div class="ha-title">{{words['home']?.['about2-title']?.[lang.slug]}}</div>
                <div class="ha-txt" [innerHTML]="words['home']?.['about2-txt']?.[lang.slug]"></div>
            </div>
            <div class="ha-item">
                <div class="ha-imgwrap"><img src="/assets/images/home-about3.png" loading="lazy" alt="about us"></div>
                <div class="ha-title">{{words['home']?.['about3-title']?.[lang.slug]}}</div>
                <div class="ha-txt" [innerHTML]="words['home']?.['about3-txt']?.[lang.slug]"></div>
            </div>
        </div>
    </section>
    <!-- partners -->
    <h2 class="ta-center" name="partners">{{words['home']?.['partners-title']?.[lang.slug]}}</h2>
    <section>
        <div class="home-partners">
            <div class="hp-right">
                <div class="hp-item">
                    <div class="hp-imgwrap">
                        <img src="/assets/images/home-partners-binance.svg" loading="lazy" alt="binance">
                    </div>
                </div>
                 <div class="hp-item">
                    <div class="hp-imgwrap">
                        <img src="/assets/images/home-partners-trust-wallet.svg" loading="lazy" alt="trust wallet">
                    </div>
                </div>
                <div class="hp-item">
                    <div class="hp-imgwrap">
                        <img src="/assets/images/home-partners-euroclear.png" loading="lazy" alt="euroclear">
                    </div>
                </div>
                <div class="hp-item">
                    <div class="hp-imgwrap">
                        <img src="/assets/images/home-partners-swissquote.svg" loading="lazy" alt="swissquote">
                    </div>
                </div>
                <!-- <div class="hp-item"> -->
                <!--     <div class="hp-imgwrap"> -->
                <!--         <img src="/assets/images/home-partners-clearstream.png" loading="lazy" alt="clearstream"> -->
                <!--     </div> -->
                <!-- </div> -->
                <!-- <div class="hp-item"> -->
                <!--     <div class="hp-imgwrap"> -->
                <!--         <img src="/assets/images/home-partners-ing4.png" loading="lazy" alt="ing"> -->
                <!--     </div> -->
                <!-- </div> -->
            </div>
            <div class="hp-left">
                <div class="hp-txt" [innerHTML]="words['home']?.['partners-txt']?.[lang.slug]"></div>
                <div class="hp-btnwrap"><a class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['partners-btn']?.[lang.slug]}}</span></a></div>
            </div>
        </div>
    </section>
    <!-- services -->
    <h2 class="ta-center" name="services">{{words['home']?.['services-title']?.[lang.slug]}}</h2>
    <section>
        <div class="home-services">
            <div class="hs-item">
                <div class="hsi-content">
                    <div class="hsi-title">{{words['home']?.['service1-title']?.[lang.slug]}}</div>
                    <div class="hsi-desc">{{words['home']?.['service1-desc']?.[lang.slug]}}</div>
                    <img src="/assets/images/home-service1.svg" loading="lazy" [alt]="words['home']?.['service1-title']?.[lang.slug]">
                </div>
            </div>
            <div class="hs-item">
                <div class="hsi-content">
                    <div class="hsi-title">{{words['home']?.['service2-title']?.[lang.slug]}}</div>
                    <div class="hsi-desc">{{words['home']?.['service2-desc']?.[lang.slug]}}</div>
                    <img src="/assets/images/home-service2.svg" loading="lazy" [alt]="words['home']?.['service2-title']?.[lang.slug]">
                </div>
            </div>
            <div class="hs-item">
                <div class="hsi-content">
                    <div class="hsi-title">{{words['home']?.['service3-title']?.[lang.slug]}}</div>
                    <div class="hsi-desc">{{words['home']?.['service3-desc']?.[lang.slug]}}</div>
                    <img src="/assets/images/home-service3.svg" loading="lazy" [alt]="words['home']?.['service3-title']?.[lang.slug]">
                </div>
            </div>
            <div class="hs-item">
                <div class="hsi-content">
                    <div class="hsi-title">{{words['home']?.['service4-title']?.[lang.slug]}}</div>
                    <div class="hsi-desc">{{words['home']?.['service4-desc']?.[lang.slug]}}</div>
                    <img src="/assets/images/home-service4.svg" loading="lazy" [alt]="words['home']?.['service4-title']?.[lang.slug]">
                </div>
            </div>
            <div class="hs-item">
                <div class="hsi-content">
                    <div class="hsi-title">{{words['home']?.['service5-title']?.[lang.slug]}}</div>
                    <div class="hsi-desc">{{words['home']?.['service5-desc']?.[lang.slug]}}</div>
                    <img src="/assets/images/home-service5.svg" loading="lazy" [alt]="words['home']?.['service5-title']?.[lang.slug]">
                </div>
            </div>
            <div class="hs-item">
                <div class="hsi-content">
                    <div class="hsi-title">{{words['home']?.['service6-title']?.[lang.slug]}}</div>
                    <div class="hsi-desc">{{words['home']?.['service6-desc']?.[lang.slug]}}</div>
                    <img src="/assets/images/home-service6.svg" loading="lazy" [alt]="words['home']?.['service6-title']?.[lang.slug]">
                </div>
            </div>
        </div>
    </section>
    <!-- exchange -->
    <h2 class="ta-center compact">{{words['home']?.['change-title']?.[lang.slug]}}</h2>
    <h3  class="ta-center" [innerHTML]="words['home']?.['change-subtitle']?.[lang.slug]"></h3>
    <section>
        <div class="home-changes">
            <div class="hc-item">
                <div class="hc-title"><span class="eth">{{words['home']?.['change1-title']?.[lang.slug]}}</span></div>
                <div class="hc-value">{{words['home']?.['change1-value']?.[lang.slug]}}</div>
                <div class="hc-percent">{{words['home']?.['change1-percent']?.[lang.slug]}}</div>
                <div class="hc-note">{{words['home']?.['change-last']?.[lang.slug]}}</div>
            </div>
            <div class="hc-item">
                <div class="hc-title"><span class="btc">{{words['home']?.['change2-title']?.[lang.slug]}}</span></div>
                <div class="hc-value">{{words['home']?.['change2-value']?.[lang.slug]}}</div>
                <div class="hc-percent">{{words['home']?.['change2-percent']?.[lang.slug]}}</div>
                <div class="hc-note">{{words['home']?.['change-last']?.[lang.slug]}}</div>
            </div>
            <div class="hc-item">
                <div class="hc-title"><span class="eth">{{words['home']?.['change3-title']?.[lang.slug]}}</span></div>
                <div class="hc-value">{{words['home']?.['change3-value']?.[lang.slug]}}</div>
                <div class="hc-percent">{{words['home']?.['change3-percent']?.[lang.slug]}}</div>
                <div class="hc-note">{{words['home']?.['change-last']?.[lang.slug]}}</div>
            </div>
        </div>
    </section>
</div>
<!-- power -->
<div class="home-power2">
    <div class="hp2-right">
      <img src="/assets/images/home-power2.png" />
    </div>
    <div class="hp2-left">
        <div class="hp2-content">
            <div class="hp2-logo"><img src="/assets/images/logo.svg" alt="logo"></div>
            <div class="hp2-title">{{words['home']?.['power-title']?.[lang.slug]}}</div>
            <div class="hp2-txt">{{words['home']?.['power-txt']?.[lang.slug]}}</div>
            <div class="hp2-btnwrap"><a class="btn" (click)="onPromoBtnClick()"><span>{{words['home']?.['power-btn']?.[lang.slug]}}</span></a></div>
        </div>
    </div>
</div>
