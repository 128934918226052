import { Component, Input } from "@angular/core";
import { ICard } from "src/app/model/entities/card.interface";
import { TerminalComponent } from "../terminal.component";

@Component({
    selector: "cards-terminal",
    templateUrl: "cards-terminal.component.html",
    styleUrls: ["../terminal.component.scss"],
})
export class CardsTerminalComponent extends TerminalComponent {
    @Input() cards: ICard[];
    public current: number = 0;
    
    get card(): ICard {return this.cards[this.current];}
}