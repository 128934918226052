<div class="win" #win>
    <ng-container *ngIf="ready">
        <main>
            <the-header></the-header>
            <router-outlet></router-outlet>
        </main>        
        <the-footer></the-footer>
        <popup-register [(active)]="pRegisterActive"></popup-register>
        <popup-registered [(active)]="pRegisteredActive"></popup-registered>
        <popup-login [(active)]="pLoginActive"></popup-login>
        <popup-logout [(active)]="pLogoutActive"></popup-logout>
    </ng-container>
</div>
