import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { cfg } from "src/app/config";
import { ILang } from "src/app/model/entities/lang.interface";
import { IUfiles } from "src/app/model/entities/ufile.interface";
import { IWords } from "src/app/model/entities/words.interface";
import { AppService } from "../../services/app.service";

@Component({
    selector: "the-footer",
    templateUrl: "footer.component.html",
    styleUrls: ["footer.component.scss"],
})
export class FooterComponent implements AfterViewInit {
    @ViewChild("footer", {static: false}) footerRef: ElementRef; 

    constructor(private appService: AppService) {}

    get lang(): ILang {return this.appService.lang;}
    get words(): IWords {return this.appService.words;}   
    get staticUrl(): string {return cfg.staticUrl;}
    get ufiles(): IUfiles {return this.appService.ufiles;}

    public ngAfterViewInit(): void {
        this.appService.footer = this.footerRef.nativeElement;
    }
}