import { Component } from "@angular/core";
import { PopupReceiveComponent } from "../receive.component";

@Component({
    selector: "receive-crypto",
    templateUrl: "receive-crypto.component.html",
    styleUrls: [
        "../../popup.component.scss",
        "../receive.component.scss",
        "receive-crypto.component.scss",
    ],
})
export class PopupReceiveCryptoComponent extends PopupReceiveComponent {}
