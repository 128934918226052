import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AppService } from "src/app/common/services/app.service";
import { TransactionRepository } from "src/app/common/services/repositories/transaction.repository";
import { ITransactionCreate } from "src/app/model/dto/transaction.create.interface";
import { ICard } from "src/app/model/entities/card.interface";
import { IKeyValue } from "src/app/model/keyvalue.interface";
import { PopupComponent } from "../../popup.component";

@Component({
    selector: "send-bank",
    templateUrl: "send-bank.component.html",
    styleUrls: [
        "../../popup.component.scss",
        "../../../../styles/forms.scss",
    ],
})
export class PopupSendBankComponent extends PopupComponent implements OnChanges {
    @Input() card: ICard;
    @Output() sent: EventEmitter<void> = new EventEmitter();
    public mode: string = "no"; // no | iban
    public ca_no: string = "";
    public ca_iban: string = "";
    public value: number = null;
    public lastname: string = "";
    public firstname: string = "";
    public middlename: string = "";
    public errors: IKeyValue<string> = {};
    public loading: boolean = false;    

    constructor(
        protected appService: AppService,
        protected transactionRepository: TransactionRepository,
    ) 
    {
        super(appService);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.active) {
            this.errors = {};
        }
    }

    public onModeChanged(): void {
        const resettingField = this.mode === "no" ? "ca_iban" : "ca_no";
        this[resettingField] = "";        
    }

    public removeNonDigits(param: string): void {
        this[param] = this[param].replace(/\D/g,'');
    }

    public removeNonLatin(param: string): void {
        this[param] = this[param].replace(/[^a-z0-9\s-]/gi, ''); // latin, digits, space, dash
    }

    public async onSubmit(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.loading = true;
            const dto: ITransactionCreate = {card_id: this.card.id, value: -this.value};
            dto[`ca_${this.mode}`] = this[`ca_${this.mode}`];
            const statusCode = await this.transactionRepository.create(dto);
            this.loading = false;
            this.onClose();  
            this.reset();

            if (statusCode === 201) {
                this.sent.emit();
            } else {
                this.appService.notifyError(this.words['errors']?.['common']?.[this.lang.slug]);
            } 
        } catch (err) {
            this.appService.notifyError(err);
            this.loading = false;
        }
    } 

    private reset(): void {
        this.ca_iban = "";
        this.ca_no = "";
        this.lastname = "";
        this.firstname = "";
        this.middlename = "";
        this.value = null;
    }

    private validate(): boolean {
        let error = false;

        if (this.mode === "no" && this.ca_no.length !== 16) {
            this.errors["ca_no"] = "card-no";
            error = true;
        } else {
            this.errors["ca_no"] = null;
        }

        if (this.mode === "iban" && !this.ca_iban) {
            this.errors["ca_iban"] = "required";
            error = true;
        } else {
            this.errors["ca_iban"] = null;
        }

        if (!this.lastname) {
            this.errors["lastname"] = "required";
            error = true;
        } else {
            this.errors["lastname"] = null;
        }

        if (!this.firstname) {
            this.errors["firstname"] = "required";
            error = true;
        } else {
            this.errors["firstname"] = null;
        }

        if (!Number.isInteger(this.value) || this.value < 0) {
            this.errors["value"] = "amount";
            error = true;
        } else if (this.value > this.card.balance) {
            this.errors["value"] = "insufficient";
            error = true;
        } else {
            this.errors["value"] = null;
        }

        return !error;
    }
}