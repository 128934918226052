<footer name="footer" #footer>
    <div class="f-content">
        <div class="f-main">
            <div class="f-contacts">
                <div class="fc-logo"><a routerLink="/{{lang.slug}}"><img src="/assets/images/logo.svg" alt="logo"></a></div>
                <div class="fc-item phone"><a href="tel:{{words['contacts']?.['phone-canonical']?.[lang.slug]}}">{{words['contacts']?.['phone']?.[lang.slug]}}</a></div>
                <div class="fc-item email"><a href="mailto:{{words['contacts']?.['email']?.[lang.slug]}}">{{words['contacts']?.['email']?.[lang.slug]}}</a></div>
                <div class="fc-soc">
                    <a [href]="words['contacts']?.['tg']?.[lang.slug] | safe:'url'"><img src="/assets/images/tg.svg" alt="telegram"></a>
                    <a [href]="words['contacts']?.['wa']?.[lang.slug] | safe:'url'"><img src="/assets/images/wa.svg" alt="telegram"></a>
                </div>
            </div>
            <div class="f-docs">
                <div class="fd-item" *ngIf="ufiles['terms']"><a href="/ufiles/{{ufiles['terms'].fileurl}}" download="{{ufiles['terms'].filename}}">{{words['footer']?.['terms']?.[lang.slug]}}</a></div>
                <div class="fd-item" *ngIf="ufiles['fees']"><a href="/ufiles/{{ufiles['fees'].fileurl}}" download="{{ufiles['fees'].filename}}">{{words['footer']?.['fees']?.[lang.slug]}}</a></div>
                <div class="fd-item" *ngIf="ufiles['contest']"><a href="/ufiles/{{ufiles['contest'].fileurl}}" download="{{ufiles['contest'].filename}}">{{words['footer']?.['contest']?.[lang.slug]}}</a></div>
                <div class="fd-item" *ngIf="ufiles['travel']"><a href="/ufiles/{{ufiles['travel'].fileurl}}" download="{{ufiles['travel'].filename}}">{{words['footer']?.['travel']?.[lang.slug]}}</a></div>
                <div class="fd-item" *ngIf="ufiles['guarantees']"><a href="/ufiles/{{ufiles['guarantees'].fileurl}}" download="{{ufiles['guarantees'].filename}}">{{words['footer']?.['guarantees']?.[lang.slug]}}</a></div>
            </div>
            <div class="f-logos">
                <div class="fl-item"><img src="/assets/images/visa.svg" alt="visa"></div>
                <div class="fl-item"><img src="/assets/images/mastercard.svg" alt="mastercard"></div>
            </div>
        </div>
        <div class="f-registration" [innerHTML]="words['footer']?.['registration']?.[lang.slug]"></div>
        <div class="f-bottom">
            <a routerLink="/{{lang.slug}}/privacy">{{words['footer']?.['privacy']?.[lang.slug]}}</a>
        </div>
    </div>
</footer>
