<div class="mm" [class.active]="active">
    <div class="mm-content">
        <nav class="mm-menu">
            <ul>
                <li><a (click)="onMenuClick('about')">{{words['header']?.['about']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('partners')">{{words['header']?.['partners']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('services')">{{words['header']?.['services']?.[lang.slug]}}</a></li>
                <li><a (click)="onMenuClick('footer')">{{words['header']?.['contact']?.[lang.slug]}}</a></li>
            </ul>
        </nav>
        <div class="mm-phone">
            <a href="tel:{{words['contacts']?.['phone-canonical']?.[lang.slug]}}">{{words['contacts']?.['phone']?.[lang.slug]}}</a>
        </div>
        <div class="mm-langs">
            <ul>
                <li *ngFor="let l of langs"><a [routerLink]="getLangLink(l)" [class.active]="lang.id === l.id">{{l.slug}}</a></li>
            </ul>
        </div>
    </div>
</div>
