import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "src/app/common/components/components.module";
import { CardComponent } from "./components/card/card.component";
import { CardsSliderComponent } from "./components/cards-slider/cards-slider.component";
import { ListTransactionsComponent } from "./components/list-transactions/list-transactions.component";
import { CardTerminalComponent } from "./components/terminals/card-terminal/card-terminal.component";
import { CardsTerminalComponent } from "./components/terminals/cards-terminal/cards-terminal.component";
import { IndexAccountPage } from "./pages/index/index.account.page";
import { LogoutAccountPage } from "./pages/logout/logout.account.page";
import { NotificationsAccountPage } from "./pages/notifications/notifications.account.page";

let routes = RouterModule.forChild ([            
	{path: "", component: IndexAccountPage},	
	{path: "notifications", component: NotificationsAccountPage},	
	{path: "logout", component: LogoutAccountPage},	
]);

@NgModule({	
    imports: [	
		CommonModule,
		RouterModule,  
		ComponentsModule,            
        routes,		
	],
	declarations: [
		IndexAccountPage,
		NotificationsAccountPage,
		LogoutAccountPage,
		CardComponent,
		CardTerminalComponent,
		CardsTerminalComponent,
		CardsSliderComponent,
		ListTransactionsComponent,
	],    		    
})
export class AccountModule {}