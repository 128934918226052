<div class="ct" *ngIf="cards.length">
    <div class="ct-card" [class.padded]="cards.length === 1">
        <cards-slider *ngIf="cards.length > 1" [cards]="cards" (currentChange)="current = $event"></cards-slider>
        <the-card *ngIf="cards.length === 1" [card]="card"></the-card>
    </div>
    <div class="ct-btns padded">
        <div class="ct-btnwrap"><button type="button" class="btn" (click)="onSend()">{{words['account']?.['send']?.[lang.slug]}}</button></div>
        <div class="ct-btnwrap"><button type="button" class="btn contrast" (click)="onReceive()">{{words['account']?.['receive']?.[lang.slug]}}</button></div>
    </div>
    <div class="ct-transactions padded">
        <list-transactions [card]="card" [reload]="reloadInitiator"></list-transactions>
    </div>
    <ng-container *ngIf="card.type.subtype === 'bank'">
        <send-bank [card]="card" [(active)]="pSendBankActive" (sent)="onTransactionSent()"></send-bank>
        <receive-bank [card]="card" [(active)]="pReceiveBankActive"></receive-bank>
    </ng-container>
    <ng-container *ngIf="card.type.subtype === 'crypto'">
        <send-crypto [card]="card" [(active)]="pSendCryptoActive" (sent)="onTransactionSent()"></send-crypto>
        <receive-crypto [card]="card" [(active)]="pReceiveCryptoActive"></receive-crypto>
    </ng-container>
</div>
