import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { CookieService } from './cookie.service';
import { DataService } from './data.service';
import { AuthGuard } from './guards/auth.guard';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { CardRepository } from './repositories/card.repository';
import { LangRepository } from './repositories/lang.repository';
import { NotificationRepository } from './repositories/notification.repository';
import { PageRepository } from './repositories/page.repository';
import { PaysystemRepository } from './repositories/paysystem.repository';
import { SettingRepository } from './repositories/setting.repository';
import { TransactionRepository } from './repositories/transaction.repository';
import { UfileRepository } from './repositories/ufile.repository';
import { WordRepository } from './repositories/word.repository';

@NgModule({
    declarations: [],
    exports: [],
    providers: [        
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
        AppService,
        DataService,
        CookieService,
        AuthService,
        AuthGuard,
        SettingRepository,       
        LangRepository,      
        WordRepository,  
        UfileRepository,   
        PageRepository,
        CardRepository,
        TransactionRepository,
        NotificationRepository,
        PaysystemRepository,
    ],
})
export class ServicesModule {}
