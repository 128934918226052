import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DirectivesModule } from "../directives/directives.module";
import { PipesModule } from "../pipes/pipes.module";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { MMAccountComponent } from "./mobile-menus/mm-account/mm-account.component";
import { MMMainComponent } from "./mobile-menus/mm-main/mm-main.component";
import { PanelLogoutComponent } from "./panels/logout/panel-logout.component";
import { PanelNotificationsComponent } from "./panels/notifications/panel-notifications.component";
import { PopupLoginComponent } from "./popups/login/popup-login.component";
import { PopupLogoutComponent } from "./popups/logout/popup-logout.component";
import { PopupReceiveBankComponent } from "./popups/receive/bank/receive-bank.component";
import { PopupReceiveCryptoComponent } from "./popups/receive/crypto/receive-crypto.component";
import { PopupRegisterComponent } from "./popups/register/popup-register.component";
import { PopupRegisteredComponent } from "./popups/registered/popup-registered.component";
import { PopupSendBankComponent } from "./popups/send/bank/send-bank.component";
import { PopupSendCryptoComponent } from "./popups/send/crypto/send-crypto.component";

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,    
        PipesModule,    
        DirectivesModule,     
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        PopupRegisterComponent,
        PopupRegisteredComponent,
        PopupLoginComponent,
        PopupLogoutComponent,
        PopupSendBankComponent,
        PopupSendCryptoComponent,
        PopupReceiveBankComponent,
        PopupReceiveCryptoComponent,
        PanelNotificationsComponent,
        PanelLogoutComponent,
        MMMainComponent,
        MMAccountComponent,
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        PopupRegisterComponent,
        PopupRegisteredComponent,
        PopupLoginComponent,
        PopupLogoutComponent,
        PopupSendBankComponent,
        PopupSendCryptoComponent,
        PopupReceiveBankComponent,
        PopupReceiveCryptoComponent,
        PanelNotificationsComponent,
        PanelLogoutComponent,
        MMMainComponent,
        MMAccountComponent,
    ],      
    providers: [],
})
export class ComponentsModule {}
